<template>
  <section class="productions">
    <div style="float:left;margin-top:10px;margin-left:1%;"><Menu /></div>

    <div class="title-container">
      <h1 class="productions-header font-productions">Realizacje</h1>
      <div class="title-underline"></div>
      <div class="title-upperline"></div>
    </div>
    <div style="clear: both"></div>
    <div class="mock-up-container">
      <div
        v-on:click="openUrlInNewTab('https://stop-fon.proton.pl/')"
        class="mock-up-large"
        id="stop-fon-mock-up"
        v-scrollanimation
      >
        <div class="mock-up-text">
          <h3>
            Strona internetowa wykonana na zlecenie Proton Innowacje SP. Z O.O.
          </h3>
          <p>
            Technologie wykorzystane w projekcie:<br /><span
              >HTML5, CSS3, JavaScript, bootstrap4, jQuery.</span
            >
          </p>
        </div>
      </div>

      <div class="mock-up-small" id="resolve-mock-up" v-scrollanimation v-on:click="openUrlInNewTab('https://www.resolve-main.com/')">
        <div class="mock-up-text">
          <h3>
            Sklep internetowy dla zagranicznego serwera gry.<br />
            Posiada integrację z PayPal, serwerem gry oraz platformą steam.
          </h3>
          <p>
            Dostarczyliśmy również oprogramowanie serwera. Technologie
            wykorzystane w projekcie:<br /><span>
              HTML5, CSS3, JavaScript, PHP, C#, bootstrap4, jQuery.</span
            >
          </p>
        </div>
      </div>
      <div class="mock-up-small" id="rogab-mock-up" v-scrollanimation v-on:click="openUrlInNewTab('https://rogabtattoo.pl/')">
        <div class="mock-up-text">
          <h3>
            Strona internetowa wykonana na zlecenie RogabTattoo. Strona typu One Page o tematyce tatuaży.
          </h3>
          <p>
            Technologie wykorzystane w projekcie:<br /><span
              >HTML5, CSS3, JavaScript, bootstrap4, jQuery, PHP.</span
            >
          </p>
        </div>
      </div>
      <div class="mock-up-large" id="gardenprzech-mock-up" v-scrollanimation v-on:click="openUrlInNewTab('https://gardenprzech.pl/')">
              <div class="mock-up-text">
          <h3>
            Strona internetowa wykonana na zlecenie GardenPrzech. 
            Strona typu Multi Page o tematyce usług ogrodniczych.
          </h3>
          <p>
            Technologie wykorzystane w projekcie:<br /><span
              >HTML5, CSS3, JavaScript, bootstrap4, jQuery, PHP.</span
            >
          </p>
        </div>
      </div>
      <div class="mock-up-small" v-scrollanimation>
        <div class="mock-up-text">
          <h3>Miejsce na twoją stronę :)</h3>
        </div>
      </div>
      <div class="mock-up-large" v-scrollanimation>
        <div class="mock-up-text">
          <h3>Miejsce na twoją stronę :)</h3>
        </div>
      </div>
    </div>
    <div class="left-line"></div>
    <footerm />
  </section>
</template>

<script>
import footerm from "@/components/footer.vue";
import Menu from "@/components/MenuBurger.vue";
export default {
  name: "Projects",
  components: {
    Menu,
    footerm
  },
  data() {
    return {
      mockupChanged: false
    };
  },
  methods: {
    openUrlInNewTab: function(url) {
      window.open(url, "_blank").focus();
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/Productions/_Productions.scss";
@import "@/assets/styles/Productions/responsive/xlarge_D.scss";
@import "@/assets/styles/Productions/responsive/large_D.scss";
@import "@/assets/styles/Productions/responsive/medium_D.scss";
@import "@/assets/styles/Productions/responsive/small_D.scss";
@import "@/assets/styles/Productions/responsive/xsmall_D.scss";
</style>
